<template>
  <v-container style="height: 100%" class="bg-glass-effect-lighter px-3 py-2 fh" fluid>
    <v-row class="px-6 py-5 notification-header" justify="center" align="center" >
      <v-col class="pa-0 ma-0" cols="1">
        <v-btn icon color="#EA4965" @click="()=>($router.go(-1))">
          <img style="width: 28px; height: 23px" src="/img/icons/common/back-arrow.svg"/>
        </v-btn>
      </v-col>
      <v-col class="pl-8 pa-0 ma-0" cols="2">
        <v-avatar size="48">
          <img :src="sender.thumbnail" >
        </v-avatar>
      </v-col>
      <v-col class="pa-0 ma-0" cols="9">
        <p class="text-center pa-0 ma-0 sender-name fill-height align-content-center justify-center">{{sender.name}}</p>
      </v-col>
    </v-row>
    <v-row id="notification-body" class="px-6 fh notification-body"  >
      <div class="d-flex flex-column-reverse fw " v-for="allMessages in getAllMessages()" :key="allMessages.senderId" >
        <v-card
                v-for="message in allMessages.messages"
                :key="message.id"
                class="notification-card pa-4 my-2 "
                outlined
                tile
        >
          <p class="notification-text">
             {{ message.message.body }}
          </p>

          <v-card v-if="message.message.messageType" class="message-inside-card mt-2" :set="messageInside = JSON.parse(message.message.messageType)" @click="navigateMessage(messageInside)">
            <v-card-text class="py-0 px-3">
              <v-row class="py-0">
                <v-col cols="2" class="mt-0">
                  <v-avatar v-if="messageInside.icon" color="red" size="32" >
                    <v-icon color="white" small>{{messageInside.icon}}</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="pr-2">
                  <p class="message-inside-title">{{ messageInside.title }}</p>
                  <p class="message-inside-text">{{ messageInside.text }}</p>
                  <p v-if=" messageInside.remember" class="message-inside-remember mt-2"><v-icon class="mr-2" style="color: lightgray" small>far fa-bell</v-icon>{{ messageInside.remember }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <p class="message-time text-right ma-0 pt-5">
            {{ getMessageDate(message.message.sentDateTime) }}
          </p>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import MessageService from "../../services/socialnetwork/MessageService";
import store from "../../store/store";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment"
export default {
  name: "chat",
  components: {
  },
  data: () => ({
    sender : {}
  }),
  created () {
    this._messageService = new MessageService();
    moment.locale('pt-br');
    this.loadMessages();
  },
  methods:{
    ...mapMutations(['loading', 'setAllMessagesBySender']),
    ...mapGetters(["getAllMessages"]),
    loadMessages(){
      this.loading(true);
      this.sender = this.$route.params.sender;
      this.messagesGroupingBySender = this.getAllMessagesBySender(this.sender.id);
      this._messageService.GetMessagesBySender(this.sender.id)
        .then((response) => {
          const messages = {
            senderId: this.sender.id,
            messages: response.data.content
          }
          this.setAllMessagesBySender(messages);
        })
        .catch(error => {
          store.commit('showmsg', { text: "Erro ao listar Mensagens", type: "error" });
        })
        .finally(() => {
          this.loading(false);
        })
    },
    getAllMessagesBySender(){
      if(!this.sender.id)
        return [];

      let messagesBySender = this.getAllMessages().find(m => {
        return m.senderId == this.sender.id
      });

      return messagesBySender ? messagesBySender.messages : [];
    },
    getMessageDate(date){
      const today = moment().format('L');
      const messageDate = moment(date).format('L');
      return (today == messageDate) ? moment(date).format('LT') : moment(date).format("DD [de] MMMM");
    },
    navigateMessage(message){
      //TODO go to route by type and params
    }
  }
}
</script>

<style scoped>
  .notification-header{
    background-color: #292867;
    position: fixed;
    z-index: 1;
    width: 100%;
  }

  .notification-body {
    max-height: calc(100vh - 100px) !important;
    overflow: visible !important;
    position: relative;
    overflow-y: scroll !important;
    top: 9%;
  }

  .sender-name{
    font-family: "Open Sans";
    color: white;
    font-size: 18px;
  }

  .notification-card {
    background-color: #F8B8CF;
    border-radius: 25px 25px 25px 10px !important;
  }

  .notification-text {
    font-family: "Open Sans";
    line-height: 25px;
    font-size: 16px;
  }

  .message-time{
    font-family: "Open Sans";
    font-size: 14px;
    color: gray;
  }

  .message-inside-card {
    max-width: 97% !important;
    min-width: 97% !important;
    background-color: #292867;
    border-radius: 25px 25px 25px 25px !important;
  }

  .message-inside-title{
    color: white;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .message-inside-text {
    color: white;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .message-inside-remember {
    color: lightgray;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
  }
</style>


